<template>
    <!-- 访客管理详情 -->
    <div class="visitorDetail">
        <div class="mask"></div>
        <div class="detailsCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="detailsFun"></i>
            </div>
            <div class="cardCon">
                <div class="cardConText" v-for="(itme, index) in details" :key="index">
                    <div class="conTextTitle">{{ itme.title }}：</div>
                    <div class="conTextName">{{ itme.text }}</div>
                </div>
                <div class="cardConText">
                    <div class="conTextTitle">人像照片：</div>
                    <img class="conTextImg" :src="photo">
                </div>
            </div>
            <div class="button">
                <el-button type="primary" @click="detailsFun">关 闭</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    // let Base64 = require('js-base64').Base64;

    export default {
        name: 'visitorDetail',
        props: {
            // 到访详情数据
            tableData: {
                type: Object,
                default() {
                    return {}
                },
            },
        },
        data() {
            return {
                photo:'',
                catdTitle: '访客详情',
                // 详情内容
                details: [
                    {
                        title: '访客姓名',
                        id:'name',
                        text: '',
                    },
                    {
                        title: '手机号码',
                        id:'phoneNo',
                        text: '',
                    },
                    {
                        title: '身份证号码',
                        id:'cardNo',
                        text: '',
                    },
                    {
                        title: '受访人',
                        id:'sufferName',
                        text: '',
                    },
                    {
                        title: '受访人手机号',
                        id:'sufferPhone',
                        text: '',
                    },
                    {
                        title: '受访人组织',
                        id:'sufferDept',
                        text: '',
                    },
                    {
                        title: '来访事由',
                        id:'visitReason',
                        text: '',
                    },
                    {
                        title: '申请访问时段',
                        id:'visitTimeStart',
                        text: '',
                    },
                    {
                        title: '申请时间',
                        id:'createdAt',
                        text: '',
                    },
                    {
                        title: '审核人',
                        id:'checkUserName',
                        text: '',
                    },
                    {
                        title: '状态',
                        id:'examineC',
                        text: '',
                    },
                    // {
                    //     title: '设备下发状态',
                    //     id:'deviceIssueC',
                    //     text: '',
                    // },
                    // {
                    //     title: '生效状态',
                    //     id:'takeStateC',
                    //     text: '',
                    // },
                ],
            }
        },
        created() {
            this.photo='data:image/jpeg;base64,'+this.tableData.headPic
            
            this.details.map(val=>{
                val.text=this.tableData[val.id]
                if(val.id==='visitTimeStart'){
                    val.text=this.tableData.visitTimeStart+' — '+this.tableData.visitTimeEnd
                }
                if(val.id==='sufferDept'){
                    val.text=this.tableData.sufferDept+' - '+this.tableData.sufferDeptSon
                }
            })
        },
        methods: {
            // 点击关闭
            detailsFun() {
                this.$emit('details-fun')
            },
        },
    }
</script>
<style lang="scss" scoped>
    .visitorDetail {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        .detailsCard {
            width: 700px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .cardCon {
                display: flex;
                flex-wrap: wrap;
                padding: 20px 40px 10px;
                border-top: 1px #f0f0f0 solid;
                border-bottom: 1px #f0f0f0 solid;
                .cardConText {
                    width: 50%;
                    display: flex;
                    margin-bottom: 12px;
                    .conTextTitle {
                        width: 118px;
                        flex: none;
                        margin-right: 6px;
                        text-align: right;
                    }
                    .conTextImg{
                        width: 120px;
                    }
                }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
