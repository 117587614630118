<template>
    <!-- 访客审核 -->
    <div class="visitorExamine">
        <div class="mask"></div>
        <div class="detailsCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="visitorExamineFun"></i>
            </div>
            <div class="cardContent">
                <div class="cardConTit">来访信息</div>
                <div class="cardCon">
                    <div class="cardConText" v-for="(itme, index) in details" :key="index">
                        <div class="conTextTitle">{{ itme.title }}：</div>
                        <div class="conTextName">{{ itme.text }}</div>
                    </div>
                    <div class="cardConText">
                        <div class="conTextTitle">人像照片：</div>
                        <img class="conTextImg" :src="photo">
                    </div>
                </div>
                <div class="cardConTit">审核</div>
                <el-form :model="examime" ref="companyForm" label-width="110px">
                    <el-form-item label="申请访问时间段">
                        <el-date-picker
                            @change="timeChange"
                            v-model="examime.visitorTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="审核结果">
                        <el-radio-group v-model="examime.visitorResult">
                            <el-radio :label="2">审核通过</el-radio>
                            <el-radio :label="-1">审核不通过</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div class="button">
                <el-button @click="visitorExamineFun">取 消</el-button>
                <el-button type="primary" @click="visitorExamineOK">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'

    export default {
        name: 'visitorExamine',
        props: {
            // 到访详情数据
            tableData: {
                type: Object,
                default() {
                    return {}
                },
            },
        },
        data() {
            return {
                photo:'',
                catdTitle: '访客审核',
                // 表单
                examime:{
                    // 申请访问时间段
                    visitorTime:'',
                    // 审核结果
                    visitorResult:2,
                },
                // 详情内容
                details: [
                    {
                        title: '访客姓名',
                        id:'name',
                        text: '',
                    },
                    {
                        title: '手机号码',
                        id:'phoneNo',
                        text: '',
                    },
                    {
                        title: '身份证号码',
                        id:'cardNo',
                        text: '',
                    },
                    {
                        title: '受访人',
                        id:'sufferName',
                        text: '',
                    },
                    {
                        title: '受访人手机号',
                        id:'sufferPhone',
                        text: '',
                    },
                    {
                        title: '受访人组织',
                        id:'sufferDept',
                        text: '',
                    },
                    {
                        title: '来访事由',
                        id:'visitReason',
                        text: '',
                    },
                    {
                        title: '申请时间',
                        id:'createdAt',
                        text: '',
                    },
                ],
            }
        },
        created() {
            if(this.tableData.headPic){
                this.photo='data:image/jpeg;base64,'+this.tableData.headPic
            }
            this.examime.visitorTime=[this.tableData.visitTimeStart+' 00:00:00',this.tableData.visitTimeEnd+' 23:59:59']
            let a=''
            if(this.tableData.sufferDeptSon){
                a=' - '
            }
            this.details.map(val=>{
                val.text=this.tableData[val.id]
                if(val.id==='sufferDept'){
                    val.text=this.tableData.sufferDept+a+this.tableData.sufferDeptSon
                }
            })
        },
        methods: {
            // 时间-用户确认选定的值时触发
            timeChange() {
                // 开始时间
                let startTime = dayjs(this.examime.visitorTime[0]).format(
                    'YYYY/MM/DD HH:mm:ss'
                )
                // 结束时间
                let endTime = dayjs(this.examime.visitorTime[1]).endOf('day').format('YYYY/MM/DD HH:mm:ss')
                this.examime.visitorTime = [startTime, endTime]
            },
            // 点击关闭
            visitorExamineFun() {
                this.$emit('visitorExamine-fun')
            },
            visitorExamineOK(){
                this.$instance
                    .post('/v1/visit/checkVisit', {
                        id: this.tableData.id,
                        isCheck: this.examime.visitorResult,
                        visitTimeStart:  this.examime.visitorTime[0],
                        visitTimeEnd:  this.examime.visitorTime[1],
                    })
                    .then((res) => {
                        if (res.data.status === 200) {
                            this.$emit('visitorExamine-OK')
                            this.$message({
                                message: '审核成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch((error) => {
                        console.log('error: ', error)
                    })
                }
        },
    }
</script>
<style lang="scss" scoped>
    .visitorExamine {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        .detailsCard {
            width: 600px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .cardContent{
                padding: 20px 40px 10px;
                border-top: 1px #f0f0f0 solid;
                border-bottom: 1px #f0f0f0 solid;
                .cardConTit{
                    border-left: 3px #ff6600 solid;
                    padding-left: 10px;
                    margin-bottom: 20px;
                    font-weight: bold;
                }
            .cardCon {
                display: flex;
                flex-wrap: wrap;
                .cardConText {
                    width: 50%;
                    display: flex;
                    margin-bottom: 12px;
                    .conTextTitle {
                        width: 98px;
                        flex: none;
                        margin-right: 6px;
                        text-align: right;
                    }
                    .conTextImg{
                        width: 120px;
                    }
                }
            }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
