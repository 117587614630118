<template>
    <!-- 访客管理 -->
    <div class="visitor-admin">
        <div class="companySearch">
            <div class="titleSearch">
                <div class="titleSearchTit">受访人组织：</div>
                <el-select @change="typeChange" v-model="region" placeholder="一级组织" clearable>
                    <el-option
                        v-for="(item, index) in regions"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select class="regionTwoSty" v-model="regionTwo" placeholder="二级组织" clearable>
                    <el-option
                        v-for="(item, index) in regionTwos"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select
                    class="gateSty"
                    v-model="examine"
                    placeholder="状态"
                    clearable
                >
                    <el-option
                        v-for="(item, index) in examines"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <!-- <el-select
                    class="gateSty"
                    v-model="takeEffect"
                    placeholder="生效状态"
                    clearable
                >
                    <el-option
                        v-for="(item, index) in takeEffects"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select> -->
                <el-input
                    @keyup.enter.native="searchClick"
                    clearable
                    v-model="input"
                    placeholder="访客姓名/受访人姓名"
                ></el-input>
                <el-button
                    @click="searchClick"
                    class="titleButton"
                    type="primary"
                    icon="el-icon-search"
                    >搜索</el-button
                >
                <el-button
                    @click="myDataClick"
                    class="titleButton"
                    icon="el-icon-download"
                    :loading="exportLoad"
                    >导出
                    <a title="data"></a>
                </el-button>
                <el-button @click="codeClick" class="titleButton"
                    >访客登记二维码
                </el-button>
            </div>
        </div>
        <div class="companyTableC">
            <div class="companyTable">
                <el-table
                    v-loading="loading"
                    :data="pagingData.elements"
                    style="width: 100%"
                    height="100%"
                >
                    <el-table-column
                        prop="name"
                        label="访客姓名"
                        min-width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="phoneNo"
                        label="手机号码"
                        min-width="110"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="sufferName"
                        label="受访人"
                        min-width="70"
                    >
                    </el-table-column>
                    <el-table-column
                        label="受访人组织"
                        min-width="160"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.sufferDept }}{{scope.row.sufferDeptSon?' - ':''}}{{scope.row.sufferDeptSon}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="visitReason"
                        label="来访事由"
                        min-width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        label="申请访问时段"
                        min-width="200"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.visitTimeStart }} — {{scope.row.visitTimeEnd}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createdAt"
                        label="申请时间"
                        min-width="160"
                    >
                    </el-table-column>
                    <el-table-column label="状态" min-width="90">
                        <template slot-scope="scope">
                            <div
                            class="examineSty" 
                            :class="{examineSty2:scope.row.examineC==='审核通过',examineSty3:scope.row.examineC==='审核不通过'}"
                            >
                                {{ scope.row.examineC }}
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="设备下发状态" min-width="110">
                        <template slot-scope="scope">
                            <div
                            class="issueSty"
                            :class="{issueSty2:scope.row.deviceIssueC==='下发成功',issueSty3:scope.row.deviceIssueC==='部分下发成功'}"
                            >
                            {{ scope.row.deviceIssueC }}
                            </div>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column label="生效状态" min-width="80">
                        <template slot-scope="scope">
                            <div
                            class="examineSty3" 
                            :class="{examineSty2:scope.row.takeStateC==='已生效'}"
                            >
                                {{ scope.row.takeStateC }}
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button
                            v-if="scope.row.examineC==='待审核'"
                            @click="examineFun(scope.$index)"
                            type="text"
                            >
                                审核
                            </el-button>
                            <el-button
                            v-if="scope.row.examineC!=='待审核'" 
                            @click="detailsClick(scope.$index)" 
                            type="text" 
                            class="el-dropdown-link"
                            >
                                详情
                            </el-button>
                            <el-button 
                            v-if="scope.row.examineC==='审核通过'"
                            @click="deleteFun(scope.row, scope.$index)"
                            type="text"
                            >
                                注销
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination
                :current-page="currentPage"
                :page-size="pagingData.pageSize"
                @current-change="handleCurrentChange"
                background
                layout="total, prev, pager, next"
                :total="pagingData.totalRecords"
            >
            </el-pagination>
        </div>
        <!-- 二维码 -->
        <el-dialog title="采集二维码" :visible.sync="codeImg">
            <img :src="codeSrc" />
            <div>点击右键下载图片</div>
        </el-dialog>
        <!-- 访客审核 -->
        <visitorExamine 
        v-if="visitorExamineShow" 
        @visitorExamine-fun="visitorExamineFun" 
        @visitorExamine-OK="visitorExamineOK" 
        :tableData="pagingData.elements[deleteIndex]"
        >
        </visitorExamine>
        <!-- 访客详情 -->
        <visitorDetail 
        v-if="visitorDetailShow" 
        @details-fun="detailsfun" 
        :tableData="pagingData.elements[deleteIndex]"
        >
        </visitorDetail>
        <!-- 弹窗-注销 -->
        <deletePop
            v-if="deletePopShow"
            @deletePop-fun="deletePopFun"
            @determineDel-fun="determineDelFun"
            :companyName="companyName"
            :name="name"
            :catdTitle="catdTitle"
            :myName="myName"
        ></deletePop>
    </div>
</template>
<script>
import visitorExamine from '../../components/visitorExamine'
import visitorDetail from '../../components/visitorDetail'
import deletePop from '../../components/deletePop'

export default {
    name: 'visitor-admin',
    components: {
        visitorExamine,
        visitorDetail,
        deletePop,
    },
    data() {
        return {
            loading: false,
            // 二维码 弹窗
            codeImg: false,
            // 二维码 路径
            codeSrc: '',
            // 导出 loading
            exportLoad: false,
            // 是否显示-弹窗-访客审核
            visitorExamineShow:false,
            // 是否显示-弹窗-访客详情
            visitorDetailShow:false,
            // 是否显示-弹窗-注销
            deletePopShow: false,
            // 一级组织
            region: null,
            regions: [],
            // 二级组织
            regionTwo: null,
            regionTwos: [],
            // 状态
            examine: null,
            examines: [
                { name: '待审核', id: 0 },
                { name: '审核不通过', id: -1 },
                { name: '审核通过', id: 2 },
                { name: '已注销', id: 3 },
                { name: '已失效', id: 4 },
            ],
            // 生效状态
            takeEffect:null,
            takeEffects:[
                { name: '未生效', id: 0 },
                { name: '已生效', id: 1 },
                { name: '失效', id: 2 },
            ],
            input: '',
            // 被选中访客名称
            companyName: '',
            name: '的访客信息',
            catdTitle: '注销访客',
            myName:'注销',
            // 被选中访客下标
            deleteIndex: null,
            // 访客表格数据
            pagingData: {},
            // 分页-总条数
            totalNum: null,
            // 当前页数
            currentPage: 1,
            //列表-开始位置
            start: 1,
            // 上传的文件
            myFile: null,
        }
    },
    created() {
        // 访客列表
        this.comtanyList()
        // 受访人一级组织 下拉数据
        this.classifyGet()
    },
    methods: {
        // 点击 审核
        examineFun(index){
            this.deleteIndex = index
            this.visitorExamineShow=true
        },
        // 点击 访客审核 关闭
        visitorExamineFun(){
            this.visitorExamineShow=false
        },
        // 点击 访客审核 确定
        visitorExamineOK(){
            this.visitorExamineShow=false
        },
        // 点击 详情
        detailsClick(index){
            this.deleteIndex = index
            this.visitorDetailShow=true
        },
        // 点击 详情 关闭
        detailsfun(){
            this.visitorDetailShow=false
        },
        // 导出列表数据
        myDataClick() {
            this.exportLoad = true
            this.$instance
                .get('/v1/visit/list/download', {
                    params:{
                        pageSize:10000,
                        // 受访人一级组织
                        sufferDeptId:this.region,
                        // 受访人二级组织
                        sufferDeptSonId:this.regionTwo,
                        // 状态
                        isCheck: this.examine,
                        // 生效状态
                        takeState: this.takeEffect,
                        // 搜索关键字
                        searchKey:this.input,
                    },
                    responseType: 'arraybuffer',
                })
                .then((res) => {
                    const blob = new Blob([res.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    })
                    // IE
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, 'data')
                    } else {
                        this.imatateDownloadByA(
                            window.URL.createObjectURL(blob),
                            '访客data'
                        )
                    }
                    this.exportLoad = false
                })
                .catch((error) => {
                    console.log('error: ', error)
                    this.exportLoad = false
                })
        },
        //  @param {String} href
        //  @param {String} filename
        imatateDownloadByA(href, filename) {
            const a = document.createElement('a')
            a.download = filename
            a.style.display = 'none'
            a.href = href
            // console.log('a.href: ', a.href)
            document.body.appendChild(a)
            a.click()
            a.remove()
            window.URL.revokeObjectURL(href)
        },
        // 受访人一级组织 下拉数据
        classifyGet() {
            this.$instance
                .get('/v1/attendance/list', {
                    params: {
                        pageSize: 1000,
                    },
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.regions = res.data.data.elements
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 受访人一级组织-选中值发生变化时触发
        typeChange(value) {
            this.regionTwos=[]
            this.regionTwo=null
            this.regions.map((val) => {
                if (val.id === value&&val.children.length) {
                    this.regionTwos=val.children
                }
            })
        },
        // 点击 访客登记二维码
        codeClick() {
            this.codeImg = true
            this.$instance
                .get('/v1/visit/reg_qr_pic', {
                    params: {},
                    responseType: 'arraybuffer',
                })
                .then((res) => {
                    //自定义名字 imgUrl 将请求回来的图片信息放到里面
                    this.codeSrc =
                        'data:image/jpeg;base64,' +
                        btoa(
                            new Uint8Array(res.data).reduce(
                                (data, byte) =>
                                    data + String.fromCharCode(byte),
                                ''
                            )
                        )
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 访客管理列表
        comtanyList() {
            this.loading = true
            this.$instance
                .post('/v1/visit/visitList', {
                    pageNum: this.currentPage - this.start,
                    pageSize:10,
                    // 受访人一级组织
                    sufferDeptId:this.region,
                    // 受访人二级组织
                    sufferDeptSonId:this.regionTwo,
                    // 状态
                    isCheck: this.examine,
                    // 生效状态
                    takeState: this.takeEffect,
                    // 搜索关键字
                    searchKey:this.input,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.loading = false
                        this.pagingData = res.data.data
                        this.pagingData.elements.map((val) => {
                            // 状态
                            this.examines.map(value=>{
                                if(value.id===val.isCheck){
                                    val.examineC = value.name
                                }
                            })
                            if(val.deviceIssue===0){
                                val.deviceIssueC='未下发'
                            }else if(val.deviceIssue===1){
                                val.deviceIssueC='下发成功'
                            }else if(val.deviceIssue===2){
                                val.deviceIssueC='部分下发成功'
                            }else if(val.deviceIssue===3){
                                val.deviceIssueC='不下发'
                            }
                            if(val.takeState===0){
                                val.takeStateC='未生效'
                            }else if(val.takeState===1){
                                val.takeStateC='已生效'
                            }else if(val.takeState===2){
                                val.takeStateC='失效'
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击 搜索
        searchClick() {
            // 当前页数
            this.currentPage = 1
            this.comtanyList()
        },
        // 点击分页-当前页
        handleCurrentChange(val) {
            this.currentPage = val
            // 搜索并拉取列表
            this.comtanyList()
        },
        // 点击 注销
        deleteFun(row, index) {
            this.deletePopShow = true
            this.deleteIndex = index
            this.companyName = row.name
        },
        // 弹窗-注销-点击取消
        deletePopFun() {
            this.deletePopShow = false
        },
        // 弹窗-注销-点击确定
        determineDelFun() {
            var value = this.pagingData.elements[this.deleteIndex].id
            this.$instance
                .post('/v1/visit/del?id=' + value)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.comtanyList()
                        this.deletePopShow = false
                        this.$message({
                            message: '注销访客成功！',
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.visitor-admin {
    width: calc(100% - 40px);
    margin: 20px;
    background-color: #fff;
    // 弹窗
    & ::v-deep .el-dialog {
        width: 400px;
        .el-dialog__body {
            text-align: center;
        }
    }
    // 搜索
    & ::v-deep .companySearch {
        width: 100%;
        height: 64px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: 0px 20px;
        box-sizing: border-box;
        border-bottom: 1px #f0f0f0 solid;
        .el-button--primary {
            line-height: 32px;
            padding: 0 12px;
            border: unset;
        }
        .titleButton {
            line-height: 30px;
            padding: 0 12px;
        }
        .titleSearch {
            display: flex;
            flex-wrap: wrap;
            .titleSearchTit{
                line-height: 32px;
            }
            .regionTwoSty{
                margin-right: 14px;
            }
            .gateSty > .el-input {
                width: 120px !important;
            }
            .titleSear {
                position: relative;
                .el-select {
                    .el-input__inner {
                        padding-right: 46px;
                    }
                    .el-input__suffix {
                        margin-right: 18px;
                        .el-icon-arrow-up {
                            display: none;
                        }
                    }
                }
                .iconSearch {
                    width: 24px;
                    position: absolute;
                    top: 9px;
                    right: 12px;
                    color: rgb(182, 182, 182);
                    font-size: 14px;
                    background-color: #fff;
                    text-align: center;
                    z-index: 2;
                    cursor: pointer;
                }
            }
            .el-select > .el-input,
            .el-input {
                margin-right: 6px;
            }
            .el-select > .el-input {
                width: 110px;
            }
            .el-input {
                width: 180px;
                .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                }
                .el-input__suffix {
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .companyTableC {
        width: calc(100% - 40px);
        height: calc(100% - 144px);
        margin: 20px;
        // 表格
        & ::v-deep .companyTable {
            height: 100%;
            border: 1px #f0f0f0 solid;
            overflow: auto;
            .buttonC {
                padding-right: 10px;
            }
            .el-table td,
            .el-table th {
                height: 49px;
                padding: 4px 0;
                .el-dropdown {
                    margin: 0 8px;
                }
                .examineSty,.issueSty{
                    color: #999;
                }
                .examineSty2,.issueSty2{
                    color: rgb(53, 164, 53);
                }
                .examineSty3{
                    color: rgb(222, 49, 49);
                }
                .issueSty3{
                    color: #ff6600;
                }
                .el-dropdown {
                    margin: 0 8px;
                }
            }
        }
        // 翻页
        .el-pagination {
            text-align: right;
            margin-top: 10px;
            padding: unset;
        }
    }
}
</style>
